
import { checkIfImageExists, uploadFile } from "@/api/Upload";
import { Vue, Component } from "vue-property-decorator";

@Component({
    components: {},
})
export default class UploadFile extends Vue {
    isLoading = false;
    validInput = false;
    showOverrideOverlay = false;

    showError = false;
    errorMessage: string | null = null;

    showResult = false;
    resultUrl: string | undefined = undefined;

    id: number | null = null;

    vModel: any = [];
    selectedFiles: File[] = [];

    rules = [
        function test(value: any) {
            if (value) return true;
            return "A value is required!";
        },
    ];

    fileSizeRules = [
        function test(value: any) {
            return !value || !value.length || value[0].size < 1000000 || "File size should be less than 1 MB!";
        },
    ];

    async onUploadClicked() {
        if (this.validInput == false) return;
        if (this.id == null) return false;

        const imageExistResponse = await checkIfImageExists(this.id);

        if (imageExistResponse.success && imageExistResponse.result) {
            this.showOverrideOverlay = true;
        } else {
            await this.upload();
        }
    }

    async onOverrideClicked() {
        this.showOverrideOverlay = false;
        await this.upload();
    }

    async upload() {
        if (this.id == null) return false;

        this.isLoading = true;
        this.errorMessage = null;

        const response = await uploadFile(this.id, this.selectedFiles);
        if (response.success) {
            const result = response.result;
            if (result !== null) {
                this.showResult = true;
                this.resultUrl = result.url;
                this.id = null;
                this.vModel = [];
                this.selectedFiles = [];
            }
        } else {
            this.showError = true;
            this.errorMessage = response.errorMessage;
        }

        this.isLoading = false;

        return true;
    }

    onFilesSelected() {
        this.selectedFiles = [];

        if (Array.isArray(this.vModel)) {
            for (let i = 0; i < this.vModel.length; i++) {
                const file = this.vModel[i];
                this.selectedFiles.push(file);
            }
        } else {
            this.selectedFiles.push(this.vModel);
        }

        console.log("before test: " + this.vModel[0]);
        for (let i = 0; i < this.selectedFiles.length; i++) {
            let file = this.selectedFiles[i];
            if (file.size >= 161000000) {
                console.log("file test greater1111");
                this.showError = true;
                this.errorMessage = "The image you have selected is greater than 1MB. Please select a smaller file!";
                return false;
            }
        }
        console.log("after test");
    }
}
