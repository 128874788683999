
import { checkIfImageExists, uploadUrl } from "@/api/Upload";
import { Vue, Component } from "vue-property-decorator";

@Component({
    components: {},
})
export default class UploadUrl extends Vue {
    isLoading = false;
    validInput = false;
    showOverrideOverlay = false;

    showError = false;
    errorMessage: string | null = null;

    showResult = false;
    resultUrl: string | undefined = undefined;

    id: number | null = null;
    imageUrl: string | null = null;

    rules = [
        function test(value: any) {
            if (value) return true;
            return "A value is required!";
        },
    ];

    async onUploadClicked() {
        if (this.validInput == false) return;
        if (this.id == null) return false;
        if (this.imageUrl == null) return false;

        this.errorMessage = null;

        const imageExistResponse = await checkIfImageExists(this.id);

        if (imageExistResponse.success && imageExistResponse.result) {
            this.showOverrideOverlay = true;
        } else {
            await this.upload();
        }
    }

    async onOverrideClicked() {
        this.showOverrideOverlay = false;
        await this.upload();
    }

    async upload() {
        if (this.id == null) return false;
        if (this.imageUrl == null) return false;

        this.isLoading = true;

        const response = await uploadUrl(this.id, this.imageUrl);
        if (response.success) {
            const result = response.result;
            if (result !== null) {
                this.showResult = true;
                this.resultUrl = result.url;

                // Reset the user input section.
                this.id = null;
                this.imageUrl = null;
            }
        } else {
            this.showError = true;
            this.errorMessage = response.errorMessage;
        }

        this.isLoading = false;

        return true;
    }
}
