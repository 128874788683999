
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class Index extends Vue {
    async mounted() {
        if (this.$store.state.loggedIn) {
            this.$router.push("/Home");
        }
    }
}
