
import { Vue, Component } from "vue-property-decorator";
import AdminMenu from "@/components/Admin/AdminMenu.vue";

@Component({
    components: {
        AdminMenu,
    },
})
export default class App extends Vue {
    showMenu = false;
}
