import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";
import { getCurrentUser, isAuthenticated } from "./api/Auth";

Vue.use(VueAxios, axios);
Vue.use(VueCookies);

Vue.config.productionTip = false;
Vue.$cookies.config("5d");

async function restoreUserSession() {
    const authResponse = await isAuthenticated();
    if (authResponse.result) {
        const userResponse = await getCurrentUser();
        console.log("userResponse: " + JSON.stringify(userResponse));
        if (userResponse.success) {
            const user = userResponse.result;
            store.commit('login', user);
            console.log("restored user session: " + JSON.stringify(user));
        }
    }
    console.log("restored vm store: " + JSON.stringify(store.state))
}

router.beforeEach(async (to, from, next) => {
    console.log("fullPath: " + to.fullPath);
    console.log("name: " + to.name);
    console.log("vm store: " + JSON.stringify(store.state))


    // User is logged in, no need to go any further.
    if (store.state.loggedIn !== undefined && store.state.loggedIn === true) {
        next();
        console.log('user is logged in, allowing access to: ' + to.fullPath + ", from: " + from.fullPath);
        return;
    }

    const authResponse = await isAuthenticated();
    if (authResponse.success) {
        if (authResponse.result) {
            await restoreUserSession();
            console.log('server says yes, allowing access to: ' + to.fullPath + ", from: " + from.fullPath);
            next();
            return;
        }
    }

    if (to.name === "index") {
        next();
        return;
    }

    // if we get here, we can assume they aren't logged in.
    // Send em over to the default index.
    next({
        name: "index"
    });
    console.log('redirecting because no valid login to: ' + to.fullPath + ", from: " + from.fullPath);
    return;
});

const vm = new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
});

vm.$store = store;

console.log("starting vm");

// Here to prevent an issue with not being able to use await functions in the root of a file.
// We use a self invoking async lambda to first restore the user session
// and then launch the app.
// the app MUST be mounted after the user session has been restored otherwise issues will occur
// like a logged in user being redirected back to the login, etc.
(async () => {
    vm.$mount("#app");
})();
