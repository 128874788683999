import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        loggedIn: false,
        user: null,
        roles: [],
        clientSettings: { tableDensity: false },
    },
    mutations: {
        login(state, user) {
            state.user = user;
            state.loggedIn = true;
        },
        logout(state) {
            state.loggedIn = false;
            state.user = null;
            state.roles = [];
        },
        setRoles(state, roles) {
            state.roles = roles;
        },
        restoreSession(state, session) {
            state.loggedIn = session.loggedIn;
            state.user = session.user;
            state.roles = session.roles;
        },
    },
    actions: {},
    modules: {},
});

export default store;
