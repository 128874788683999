import axios from "@/plugins/axios";
import ApiResult from "./common/ApiResult";

export async function checkIfImageExists(id: number): Promise<ApiResult<boolean>> {
    return (await axios.get(`/upload/CheckIfImageExists/${id}`)).data;
}

export async function uploadUrl(id: number, url: string): Promise<ApiResult<any>> {
    return (await axios.post("/upload/UploadUrl", { id, url })).data;
}

export async function uploadFile(id: number, files: File[]): Promise<ApiResult<any>> {
    const formData = new FormData();

    formData.append("id", id.toString());

    for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i], files[i].name);
    }

    const response = (
        await axios.post("/upload/UploadFile", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    ).data;

    return response;
}
