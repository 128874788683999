import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Index from "../views/Index.vue";
import Home from "../views/Home.vue";
import UploadIndex from "../views/Upload/Index.vue";
import UploadFile from "../views/Upload/File.vue";
import UploadUrl from "../views/Upload/Url.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "index",
        component: Index,
    },

    {
        path: "/home",
        name: "home",
        component: Home,
    },

    {
        path: "/upload",
        name: "upload",
        component: UploadIndex,
    },
    {
        path: "/upload/file",
        name: "uploadFile",
        component: UploadFile,
    },
    {
        path: "/upload/url",
        name: "uploadUrl",
        component: UploadUrl,
    },

    {
        path: "/reports/morningreport",
        name: "morningreport",
        component: () => import("../views/Reports/MorningReport.vue"),
    },
    {
        path: "/reports/joborderreport",
        name: "joborderreport",
        component: () => import("../views/Reports/JobOrderReport.vue"),
    },
    {
        path: "/reports/retainerpitches",
        name: "retainerpitches",
        component: () => import("../views/Reports/RetainerPitches.vue"),
    },
    // {
    //     path: "/reports/crushitreport",
    //     name: "crushitreport",
    //     component: () => import("../views/Reports/CrushItReport.vue"),
    // },
    {
        path: "/reports/notereport",
        name: "crushitreport",
        component: () => import("../views/Reports/NoteReport.vue"),
    },
    {
        path: "/reports/blitznoteactivityreport",
        name: "blitznoteactivityreport",
        component: () => import("../views/Reports/BlitzNoteActivityReport.vue"),
    },
    {
        path: "/reports/cpkpis",
        name: "cpkpis",
        component: () => import("../views/Reports/ClientPartnerKPIs.vue"),
    },
    {
        path: "/reports/candidatereport",
        name: "candidatereport",
        component: () => import("../views/Reports/CandidateReport.vue"),
    },
    {
        path: "/reports/testreport",
        name: "testreport",
        component: () => import("../views/Reports/TestReport.vue"),
    },
    {
        path: "/reports/taskreport",
        name: "taskreport",
        component: () => import("../views/Reports/TaskReport.vue"),
    },

    // Library pages
    {
        path: "/library/marketing",
        name: "/library/marketing",
        component: () => import("../views/Library/Marketing.vue"),
    },
    {
        path: "/library/wpplaybook",
        name: "/library/wpplaybook",
        component: () => import("../views/Library/WPPlaybook.vue"),
    },
    {
        path: "/library/msa",
        name: "/library/msa",
        component: () => import("../views/Library/MSA.vue"),
    },

    // Admin pages
    {
        path: "/admin/usermanagement",
        name: "/admin/usermanagement",
        component: () => import("../views/Admin/UserManagement.vue"),
    },
    {
        path: "/admin/syncjobmanagement",
        name: "/admin/syncjobmanagement",
        component: () => import("../views/Admin/SyncJobManagement.vue"),
    },

    // CRM Pages
    {
        path: "/bullhorn/jobdetails",
        name: "/bullhorn/jobdetails",
        component: () => import("../views/Bullhorn/JobDetails.vue"),
    },
    {
        path: "/crm/task",
        name: "/crm/task",
        component: () => import("../views/CRM/Task.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
