
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    name: "AdminMenu",
})
export default class AdminMenu extends Vue {
    isAdmin(): boolean {
        const user = this.$store.state.user;
        if (user === undefined || user == null) {
            return false;
        }
        return user.isAdmin;
    }

    admins = [
        ["User Management", "mdi-account-multiple-outline", "/Admin/UserManagement"],
        ["Sync Job Management", "mdi-account-multiple-outline", "/Admin/SyncJobManagement"],
    ];
}
