import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    customVariables: "~/assets/styles/variables.scss",
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#8bc243'
            }
        }
    }
});
