import axios from "@/plugins/axios";
import ApiResult from "./common/ApiResult";

export async function isAuthenticated(): Promise<ApiResult<boolean>> {
    return (await axios.get("/auth/IsAuthenticated")).data;
}

export async function getCurrentUser(): Promise<ApiResult<any>> {
    return (await axios.get("/auth/CurrentUser")).data;
}
